export const PREMIUM_PERKS = 'premium_perks';
export const RELEASE_ANNOUNCEMENT = 'release_announcement';
export const SL_HISTORIC_CHARTS = 'SL_historic_charts';
export const SL_WEB_CAM_SURF_HEIGHTS = 'sl_web_cam_surf_heights';
export const SL_WEB_CHARTS_LOCATION_MENU = 'sl_web_charts_location_menu';
export const SL_WEB_CHARTS_SWELL_RULER = 'sl_web_charts_swell_ruler';
export const SL_WEB_CUSTOM_FORECASTS = 'sl_web_custom_forecasts';
export const SL_WEB_FORECAST_FEEDBACK_FREQUENCY = 'sl_web_forecast_feedback_frequency';
export const SL_WEB_PAYWALLS_V2 = 'sl_web_paywalls_v2';
export const SL_WEB_SUNBUM_SPONSORSHIP = 'sl_web_sunbum_sponsorship';
export const SL_WEB_WAVE_POOL_SEARCH_RESULTS = 'sl_web_wave_pool_search_results';
export const SL_WEB_ACCOUNT_SHARING_REDIRECT = 'sl_web_account_sharing_redirect';
export const SL_WEB_PREMIUMPLUS_LIMITED_TIME_ACCESS = 'sl_web_premiumplus_limited_time_access';
export const SL_SCIENCE_POC_CONDITION_SIMILARITY = 'SL_science_poc_condition_similiarity';
export const SL_WEB_HIGH_QUALITY_REWIND_CLIPS_STILLS = 'sl_web_high_quality_rewind_clips_stills';
export const SL_WEB_CAMERA_USER_FEEDBACK_MODAL = 'SL_web_camera_user_feedback_modal';
export const SL_WEB_REWIND_RECAPS = 'SL_web_rewind_recaps';
export const SL_WEB_ACCOUNT_SHARING_BANNER = 'sl_web_account_sharing_banner';
export const SL_WEB_CAM_PAYWALL_REDESIGN = 'sl_web_cam_paywall_redesign';
export const SL_WEB_EDITORIAL_PAYWALL_CUSTOMIZATION = 'sl_web_editorial_paywall_customization';
